.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.mainDiv {
  height: 100vh;
}

.imgeCirve {
  height: 100vh;
}
